import React, {Component, createRef} from 'react';
import {createSelector} from 'reselect';
import {Field} from 'redux-form';
import {ActionBar, Alert, CheckboxInput, ChoiceField, Confirm, FieldLabel, Help, ModalForm, RioModeField, TextField} from '@automationanywhere/rio-components';
import {EditorExpressionField} from '@automationanywhere/rio-components/src/components/team/editor/EditorExpressionField';
import {
    PACKAGE_ATTRIBUTE_TYPE_TEXT,
    PACKAGE_ATTRIBUTE_TYPE_VARIABLE,

    PACKAGE_RETURN_SETTING_SESSION_TARGET,
} from '@automationanywhere/rio-components/src/editor/constants/attributes';
import {
    VALUE_TYPE_NONE,
    VALUE_TYPE_UNDEFINED,
    VALUE_TYPE_ANY,
    VALUE_TYPE_SESSION,
    VALUE_TYPE_RECORD,
    VALUE_TYPE_DICTIONARY,

    VALUE_COMPATIBLE_TYPES,
    VALUE_TYPE_LIST,
    VALUE_TYPE_STRING,
} from '@automationanywhere/rio-components/src/editor/constants/values';
import {getVariableKey, getNewVariableName} from '@automationanywhere/rio-components/src/editor/utils/variables';

import {TaskbotEntryListField} from '../TaskbotEntryListField';
import {ModalFormContent} from '../../common/ModalFormContent';
import {NODE_TYPE_COMMAND, NODE_TYPE_TRIGGER} from '../utils/nodes';
import {getTypeRenderIcon, isVariableMappable} from '../utils/types';
import {generateUUID} from '../../../util/uuid';

const getDuplicateMultipleEntryKeys = (value) => {
    const duplicateKeys = new Set();
    if (!value?.multiple?.entries?.length) {
        return duplicateKeys;
    }

    const counts = new Map();
    value.multiple.entries.forEach((entryId) => {
        const key = getVariableKey(value[`multiple_${entryId}__key`]);
        counts.set(key, (counts.get(key) ?? 0) + 1);
    });
    counts.forEach((count, key) => {
        if (count > 1) {
            duplicateKeys.add(key);
        }
    });
    return duplicateKeys;
};

class TaskbotNodeDetailsReturnTo extends Component {
    static displayName = 'TaskbotNodeDetailsReturnTo';

    static defaultValue = {
        mode: 'variable',
        variable: '',
        multiple: TaskbotEntryListField.getDefaultValue(),
        localSession: '',
        globalSession: '',
    };

    static getDefaultValue(isExpression = false) {
        return {
            mode: 'variable',
            variable: '',
            multiple: TaskbotEntryListField.getDefaultValue(isExpression),
            localSession: '',
            globalSession: '',
        };
    }

    constructor(props) {
        super(props);

        this.variableRef = createRef(null);

        this.selectValue = (props) => {
            return props.getFieldValue(props.name);
        };

        this.selectVariableOptions = createSelector(
            this.selectValue,
            (props) => props.nodeType,
            (props) => props.variables,
            (props) => props.object,
            (props) => props.returnType,
            (props) => props.returnSubtype,
            (props) => props.returnSchema,
            (variableName, nodeType, variables, object, returnType, returnSubtype, returnSchema) => {
                const options = [{value: '', label: ''}];
                if (returnType === VALUE_TYPE_UNDEFINED) {
                    return options;
                }

                const compatibleTypes = VALUE_COMPATIBLE_TYPES[returnType] || [returnType];
                const compatibleSubtypes = VALUE_COMPATIBLE_TYPES[returnSubtype] || [returnSubtype];
                const isValid = (variable) => {
                    if (!variable) {
                        return false;
                    }

                    if (nodeType === NODE_TYPE_TRIGGER && !variable.input) {
                        return false;
                    }

                    if (variableName && variable.name === variableName) {
                        return true;
                    }

                    if (variable.readOnly || !variable.type || !compatibleTypes.includes(variable.type)) {
                        return false;
                    }

                    if (variable.type === VALUE_TYPE_ANY) {
                        return true;
                    }

                    if (returnType === VALUE_TYPE_SESSION && variable.sessionType !== object.packageName) {
                        return false;
                    }

                    if (returnSubtype && returnSubtype !== VALUE_TYPE_UNDEFINED && returnSubtype !== VALUE_TYPE_ANY) {
                        if (
                            variable.subtype && variable.subtype !== VALUE_TYPE_UNDEFINED && variable.subtype !== VALUE_TYPE_ANY &&
                            !compatibleSubtypes.includes(variable.subtype)
                        ) {
                            return false;
                        }
                    }

                    if (returnSchema?.length && variable.schema?.length) {
                        if (returnSchema.length !== variable.schema.length || returnSchema.some(({name, type}, index) => {
                            const variableEntry = variable.schema[index];
                            return variableEntry.name !== name || variableEntry.type !== type;
                        })) {
                            return false;
                        }
                    }

                    return true;
                };
                if (variables.length > 0) {
                    variables
                        .filter(isValid)
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .forEach((variable) => {
                            options.push({
                                value: getVariableKey(variable.name),
                                label: variable.name,
                                description: variable.description,
                                type: variable.type,
                                subtype: variable.subtype,
                                renderIcon: getTypeRenderIcon(variable.type, variable.subtype),
                            });
                        });
                }
                return options;
            }
        );

        this.selectVariableActions = createSelector(
            (props) => props.readOnly,
            (props) => props.disabled,
            (props) => props.variableNamePattern,
            (props) => props.t,
            (readOnly, disabled, variableNamePattern, t) => {
                if (readOnly || disabled) {
                    return null;
                }

                return [{
                    name: 'create-variable',
                    aa: 'variable-create',
                    label: t('taskbot:variable-manager-variable-create-label'),
                    onClick: () => {
                        const {name, nodeType, object, sessionTypes, variables, returnName, returnType, returnSubtype, returnSchema, getFieldValue, onVariableOpen, onChange, t} = this.props;
                        if (!onVariableOpen) {
                            return;
                        }

                        let returnSessionType;
                        if (returnType === VALUE_TYPE_SESSION && object && sessionTypes.some(({packageName}) => packageName === object.packageName)) {
                            returnSessionType = object.packageName;
                        }
                        const options = {
                            name: getNewVariableName(returnName, variableNamePattern, variables),
                            type: returnType,
                            subtype: returnSubtype,
                            sessionType: returnSessionType,
                            schema: returnSchema,
                            input: nodeType === NODE_TYPE_TRIGGER || undefined,
                            readOnly: false,
                            labelSubmit: t('variables:label-create-select'),
                            noSave: true,
                        };
                        onVariableOpen('create', null, options, (variable) => {
                            setTimeout(() => {
                                const value = getFieldValue(name);
                                onChange(name, {
                                    ...value,
                                    variable: variable.name,
                                });

                                const element = this.variableRef.current?.querySelector('[tabindex="0"]');
                                if (element) {
                                    element.focus();
                                }
                            }, 20);
                        });
                    },
                }];
            }
        );

        this.selectMappingOptions = createSelector(
            (props) => props.nodeType,
            (props) => props.variables,
            (props) => props.returnType,
            (props) => props.returnSubtype,
            (nodeType, variables, returnType, returnSubtype) => {
                const options = [{value: '', label: ''}];
                const isValid = (variable) => {
                    if (!variable) {
                        return false;
                    }

                    if (nodeType === NODE_TYPE_TRIGGER && !variable.input) {
                        return false;
                    }

                    if (variable.type === VALUE_TYPE_ANY) {
                        return true;
                    }

                    if (returnType === VALUE_TYPE_DICTIONARY && returnSubtype && returnSubtype !== VALUE_TYPE_ANY && returnSubtype !== VALUE_TYPE_UNDEFINED && variable.type !== returnSubtype) {
                        return false;
                    }

                    return true;
                };
                if (variables.length > 0) {
                    variables
                        .filter(isValid)
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .forEach((variable) => {
                            options.push({
                                value: variable.key,
                                label: variable.name,
                                description: variable.description,
                                type: variable.type,
                                subtype: variable.subtype,
                                renderIcon: getTypeRenderIcon(variable.type, variable.subtype),
                            });
                        });
                }
                return options;
            }
        );

        /**
         * If we have our type changed (dynamic types are expected to do this) and the
         * mode is now invalid for our new type, change to a valid value.
         */
        this.selectReturnTypeAndValue = createSelector(
            this.selectValue,
            (props) => props.isReturnTo,
            (props) => props.returnType,
            (props) => props.returnSchema,
            (props) => props.returnSettings,
            (props) => props.readOnly,
            (props) => props.disabled,
            (value, isReturnTo, returnType, returnSchema, returnSettings, readOnly, disabled) => {
                let nextValue;
                if (readOnly || disabled || !returnType || returnType === VALUE_TYPE_UNDEFINED) {
                    return;
                }

                const {nodeType} = this.props;
                const isExpression = nodeType === NODE_TYPE_COMMAND;
                const valueMode = value?.mode;
                if (!valueMode) {
                    nextValue = TaskbotNodeDetailsReturnTo.getDefaultValue(isExpression);
                }
                else if (returnType === VALUE_TYPE_NONE) {
                    if (valueMode !== 'variable' || value.variable) {
                        nextValue = TaskbotNodeDetailsReturnTo.getDefaultValue(isExpression);
                    }
                }
                else if (isReturnTo && returnType === VALUE_TYPE_SESSION && returnSettings?.includes(PACKAGE_RETURN_SETTING_SESSION_TARGET)) {
                    if (valueMode !== 'localSession' && valueMode !== 'globalSession' && valueMode !== 'variable') {
                        nextValue = {
                            ...TaskbotNodeDetailsReturnTo.getDefaultValue(isExpression),
                            ...value,
                            mode: 'variable',
                        };
                    }
                }
                else if (isReturnTo && (returnType === VALUE_TYPE_DICTIONARY || (returnType === VALUE_TYPE_RECORD && !returnSchema?.length))) {
                    if (valueMode !== 'multiple' && valueMode !== 'variable') {
                        nextValue = {
                            ...TaskbotNodeDetailsReturnTo.getDefaultValue(isExpression),
                            ...value,
                            mode: 'variable',
                        };
                    }
                }
                else if (valueMode !== 'variable') {
                    nextValue = {
                        ...TaskbotNodeDetailsReturnTo.getDefaultValue(isExpression),
                        ...value,
                        mode: 'variable',
                    };
                }
                if (nextValue) {
                    const {name, onChange} = this.props;
                    onChange(name, nextValue);
                }
            }
        );

        this.state = {
            showQuickMap: false,
            showQuickMapCreate: false,
            showQuickMapOverwrite: false,
            showQuickMapDelete: false,
        };
    }

    componentDidMount() {
        this.selectReturnTypeAndValue(this.props);
    }

    componentDidUpdate() {
        this.selectReturnTypeAndValue(this.props);
    }

    renderLabel() {
        const {returnLabel, returnRequired, t} = this.props;
        const label = returnLabel || t('taskbot:label-return-to');
        return returnRequired ? label : `${label} ${t('label-parens-optional')}`;
    }

    renderVariable(label, help, returnRequired) {
        const {
            name, readOnly, disabled, touched, onSubmit,
            t,
        } = this.props;
        const variableOptions = this.selectVariableOptions(this.props);
        return (
            <div ref={this.variableRef} className="taskbot-node-details__field">
                <Field
                    name={`${name}.variable`}
                    label={label}
                    help={help}
                    options={variableOptions}
                    actions={this.selectVariableActions(this.props)}
                    placeholder={returnRequired ? t('label-required') : !readOnly && !disabled ? t('taskbot:label-variable-placeholder') : null}
                    onSubmit={onSubmit}
                    component={ChoiceField}
                    readOnly={readOnly}
                    disabled={disabled || variableOptions.length === 0}
                    touched={touched}
                    showMissingValue
                />
            </div>
        );
    }

    renderSessionNamespace(name, help) {
        const {
            readOnly, disabled, touched, onSubmit,
            nodeType, returnDescription, returnRequired,
            commandGroups, commandMap, commandProperties,
            globalValues, variableGroups, variableMap, variables,
            t,
        } = this.props;
        return (
            <div className="taskbot-node-details__field">
                {help && (
                    <Help className="taskbot-node-details__return-help">
                        {help}
                    </Help>
                )}
                <Field
                    name={name}
                    help={returnDescription}
                    placeholder={returnRequired ? t('label-required') : null}
                    component={EditorExpressionField}
                    type={VALUE_TYPE_STRING}
                    commandGroups={commandGroups}
                    commandMap={commandMap}
                    commandProperties={commandProperties}
                    globalValues={globalValues}
                    variableMap={variableMap}
                    variableGroups={variableGroups}
                    variables={nodeType === NODE_TYPE_TRIGGER ? [] : variables}
                    onSubmit={onSubmit}
                    readOnly={readOnly}
                    disabled={disabled}
                    touched={touched}
                    t={t}
                />
            </div>
        );
    }

    renderQuickMap() {
        const {showQuickMap, showQuickMapCreate, showQuickMapOverwrite, showQuickMapDelete} = this.state;
        if (!showQuickMap) {
            return null;
        }

        const {name, returnSchema, variables, getFieldValue, t} = this.props;
        const value = getFieldValue(name);
        const uneededEntryKeys = new Set(value?.multiple?.entries?.map((entryId) => value[`multiple_${entryId}__key`]) || null);
        const duplicateEntryKeys = getDuplicateMultipleEntryKeys(value);
        const createItems = [];
        const mapItems = [];
        const duplicateItems = [];
        const invalidItems = [];
        const addVariables = [];
        const nextSchema = [];
        let createDisabled = true;
        let overwriteDisabled = true;
        let submitDisabled = true;
        returnSchema?.forEach((schemaEntry) => {
            const key = getVariableKey(schemaEntry.name);
            uneededEntryKeys.delete(key);

            const renderIcon = getTypeRenderIcon(schemaEntry.type, schemaEntry.subtype);
            const currentVariable = variables.find((variable) => variable.key === key);
            const valueEntryKey = value?.multiple?.entries?.find((entryId) => getVariableKey(value[`multiple_${entryId}__key`]) === key);
            if (!currentVariable) {
                if (showQuickMapCreate) {
                    submitDisabled = false;
                    addVariables.push(schemaEntry);
                    nextSchema.push(schemaEntry);
                }
                createDisabled = false;
                createItems.push({
                    icon: renderIcon(),
                    label: schemaEntry.name,
                    disabled: !showQuickMapCreate,
                });
                if (valueEntryKey) {
                    overwriteDisabled = false;
                }
                return;
            }

            if (!isVariableMappable(schemaEntry, currentVariable)) {
                invalidItems.push({
                    icon: renderIcon(),
                    label: schemaEntry.name,
                    disabled: true,
                });
                return;
            }

            if (showQuickMapOverwrite || !valueEntryKey) {
                submitDisabled = false;
            }
            if (valueEntryKey) {
                overwriteDisabled = false;
            }
            if (showQuickMapOverwrite || !valueEntryKey) {
                nextSchema.push(schemaEntry);
            }
            mapItems.push({
                icon: renderIcon(),
                label: schemaEntry.name,
                disabled: Boolean(!showQuickMapOverwrite && valueEntryKey),
            });
        });

        const validDuplicateEntries = new Set();
        if (duplicateEntryKeys.size > 0) {
            returnSchema?.forEach((schemaEntry) => {
                const key = getVariableKey(schemaEntry.name);
                if (duplicateEntryKeys.has(key) && !validDuplicateEntries.has(key)) {
                    validDuplicateEntries.add(key);

                    const renderIcon = getTypeRenderIcon(schemaEntry.type, schemaEntry.subtype);
                    duplicateItems.push({
                        icon: renderIcon(),
                        label: schemaEntry.name,
                        disabled: true,
                    });
                }
            });
        }

        if (!createItems.length && !mapItems.length && !duplicateItems.length && invalidItems.length > 0) {
            return (
                <Alert
                    theme="error"
                    id="taskbot-node-details-return-to__invalid-items"
                    title={t('taskbot:taskbot-input-quickmap-title')}
                    items={invalidItems}
                    onHide={() => this.setState({showQuickMap: false})}
                    labelOk={t('label-close')}
                    show
                >
                    <Help>{t('taskbot:taskbot-input-quickmap-help')}</Help>
                </Alert>
            );
        }

        const items = [];
        if (createItems.length) {
            items.push({header: t('taskbot:taskbot-input-quickmap-group-create')}, ...createItems);
        }
        if (mapItems.length) {
            items.push({header: t('taskbot:taskbot-input-quickmap-group-map')}, ...mapItems);
        }
        if (duplicateItems.length) {
            items.push({header: t('taskbot:taskbot-input-quickmap-group-duplicate')}, ...duplicateItems);
        }
        if (invalidItems.length) {
            items.push({header: t('taskbot:taskbot-input-quickmap-group-invalid')}, ...invalidItems);
        }
        const deleteDisabled = uneededEntryKeys.size === 0 && validDuplicateEntries.size === 0;

        const doCreate = Boolean(showQuickMapCreate && !createDisabled);
        const doOverwrite = Boolean(showQuickMapOverwrite && !overwriteDisabled);
        const doDelete = Boolean(showQuickMapDelete && !deleteDisabled);
        return (
            <Confirm
                id="taskbot-node-details-return-to__quickmap"
                theme="info"
                title={t('taskbot:taskbot-input-quickmap-title')}
                items={items}
                onHide={() => this.setState({showQuickMap: false})}
                onSubmit={(submitDisabled || !nextSchema.length) && !(doCreate || doOverwrite || doDelete || mapItems.length > 0) ? null : () => {
                    const {name, nodeType, variables, onVariablesChange, onChange} = this.props;
                    if (doCreate && addVariables.length) {
                        onVariablesChange([
                            ...variables,
                            ...addVariables.map((schemaEntry) => ({
                                key: getVariableKey(schemaEntry.name),
                                name: schemaEntry.name,
                                type: schemaEntry.type,
                                subtype: schemaEntry.subtype || VALUE_TYPE_UNDEFINED,
                                sessionType: schemaEntry.sessionType || '',
                                input: nodeType === NODE_TYPE_TRIGGER,
                                output: false,
                                constant: false,
                            })),
                        ]);
                    }
                    const nextValue = {
                        ...TaskbotNodeDetailsReturnTo.getDefaultValue(nodeType === NODE_TYPE_COMMAND),
                        ...value,
                        mode: 'multiple',
                        multiple: {
                            ...TaskbotEntryListField.getDefaultValue(nodeType === NODE_TYPE_COMMAND),
                            entries: value?.multiple?.entries?.length ? [...value.multiple.entries] : [],
                            create: null,
                        },
                    };
                    if (doDelete) {
                        const foundKeys = new Set();
                        nextValue.multiple.entries = nextValue.multiple.entries.filter((entryId) => {
                            const key = getVariableKey(value[`multiple_${entryId}__key`]);
                            if (foundKeys.has(key)) {
                                return;
                            }

                            foundKeys.add(key);
                            return !uneededEntryKeys.has(key);
                        });
                    }
                    nextSchema.forEach((schemaEntry) => {
                        const key = getVariableKey(schemaEntry.name);
                        let entryId = value?.multiple?.entries?.find((entryId) => getVariableKey(value[`multiple_${entryId}__key`]) === key);
                        if (entryId && !doOverwrite) {
                            return;
                        }

                        if (!entryId) {
                            entryId = generateUUID();
                            nextValue.multiple.entries.push(entryId);
                        }
                        nextValue[`multiple_${entryId}__key`] = schemaEntry.name;
                        nextValue[`multiple_${entryId}__variable`] = schemaEntry.name;
                    });
                    onChange(name, nextValue);
                    this.setState({showQuickMap: false});
                }}
                labelCancel={t('label-cancel')}
                labelAccept={t('label-apply')}
                show
            >
                <Help>{t('taskbot:taskbot-input-quickmap-help')}</Help>
                <CheckboxInput
                    label={t('taskbot:taskbot-input-quickmap-create')}
                    value={doCreate}
                    onChange={() => this.setState({showQuickMapCreate: !showQuickMapCreate})}
                    disabled={createDisabled}
                />
                <CheckboxInput
                    label={t('taskbot:taskbot-input-quickmap-overwrite')}
                    value={doOverwrite}
                    onChange={() => this.setState({showQuickMapOverwrite: !showQuickMapOverwrite})}
                    disabled={overwriteDisabled}
                />
                <CheckboxInput
                    label={t('taskbot:taskbot-input-quickmap-delete')}
                    value={doDelete}
                    onChange={() => this.setState({showQuickMapDelete: !showQuickMapDelete})}
                    disabled={deleteDisabled}
                />
                <br/>
            </Confirm>
        );
    }

    renderMultiple() {
        const {
            nodeType,
            name, readOnly, disabled, touched, onSubmit,
            variables,
            returnSchema,
            getFormValues,
            getFieldValue,
            t,
        } = this.props;
        return (
            <div className="taskbot-node-details__returns-multiple">
                {this.renderQuickMap()}
                {!readOnly && returnSchema?.length > 0 && (
                    <ActionBar className="taskbot-node-details__returns-multiple-actions">
                        <ActionBar.Action
                            name="quickmap"
                            label={t('taskbot:taskbot-input-quickmap')}
                            fa="exchange"
                            onClick={() => this.setState({
                                showQuickMap: true,
                                showQuickMapCreate: true,
                                showQuickMapOverwrite: false,
                                showQuickMapDelete: false,
                            })}
                            disabled={disabled}
                        />
                    </ActionBar>
                )}
                <Field
                    name={`${name}.multiple`}
                    component={TaskbotEntryListField}
                    label={readOnly ? null : t('taskbot:label-return-mapping-title')}
                    labelAdd={t('taskbot:label-return-mapping-add')}
                    labelEmpty={t('taskbot:label-return-mapping-empty')}
                    columns={[
                        {
                            name: 'key',
                            label: t('taskbot:label-return-mapping-column-key'),
                            type: PACKAGE_ATTRIBUTE_TYPE_TEXT,
                        },
                        {
                            name: 'variable',
                            label: t('taskbot:label-return-mapping-column-variable'),
                            type: PACKAGE_ATTRIBUTE_TYPE_VARIABLE,
                        },
                    ]}
                    nodeType={nodeType}
                    renderEntry={({entryMode, entryId, entryName, invalid, onCancel, onAccept, className}) => {
                        let mappingOptions = this.selectMappingOptions(this.props);
                        let keyOptions;
                        const key = getFieldValue(`${entryName}__key`);
                        if (returnSchema?.length) {
                            keyOptions = returnSchema.map((entry) => ({
                                label: entry.name,
                                value: entry.name,
                                type: entry.type,
                                subtype: entry.subtype,
                                renderIcon: getTypeRenderIcon(entry.type, entry.subtype),
                            }));
                            keyOptions.unshift({
                                label: '',
                                value: '',
                            });
                            const schemaEntry = returnSchema.find((entry) => getVariableKey(entry.name) === getVariableKey(key));
                            if (schemaEntry) {
                                mappingOptions = mappingOptions.filter((option, index) => {
                                    if (index === 0) {
                                        return true;
                                    }

                                    if (schemaEntry.type === VALUE_TYPE_ANY || option.type === VALUE_TYPE_ANY) {
                                        return true;
                                    }

                                    if (schemaEntry.type !== option.type) {
                                        return false;
                                    }

                                    switch (schemaEntry.type) {
                                        case VALUE_TYPE_DICTIONARY:
                                        case VALUE_TYPE_LIST:
                                            return schemaEntry.subtype === option.subtype ||
                                                !schemaEntry.subtype || schemaEntry.subtype === VALUE_TYPE_UNDEFINED || schemaEntry.subtype === VALUE_TYPE_ANY ||
                                                !option.subtype || option.subtype === VALUE_TYPE_UNDEFINED || option.subtype === VALUE_TYPE_ANY;
                                    }

                                    return true;
                                });
                            }
                        }
                        return (
                            <ModalForm
                                key={entryId}
                                id="taskbot-node-details-return-to__return-mapping-entry"
                                className={className}
                                title={t('taskbot:label-return-mapping-entry')}
                                submitDisabled={invalid}
                                onSubmit={onAccept}
                                onHide={onCancel}
                                labelSubmit={!onAccept ? null : entryMode === 'create' ? t('label-add') : null}
                                labelCancel={entryMode !== 'create' ? t('label-close') : t('label-cancel')}
                                show
                            >
                                <ModalFormContent>
                                    {keyOptions ? (
                                        <Field
                                            key="choice"
                                            name={`${entryName}__key`}
                                            label={t('taskbot:label-return-mapping-column-key')}
                                            placeholder={t('label-required')}
                                            onSubmit={onAccept}
                                            component={ChoiceField}
                                            options={keyOptions}
                                            readOnly={readOnly}
                                            disabled={disabled}
                                            touched={touched}
                                        />
                                    ) : (
                                        <Field
                                            key="text"
                                            name={`${entryName}__key`}
                                            label={t('taskbot:label-return-mapping-column-key')}
                                            placeholder={t('label-required')}
                                            onSubmit={onAccept}
                                            component={TextField}
                                            readOnly={readOnly}
                                            disabled={disabled}
                                            touched={touched}
                                        />
                                    )}
                                    <Field
                                        name={`${entryName}__variable`}
                                        label={t('taskbot:label-return-mapping-column-variable')}
                                        placeholder={t('label-required')}
                                        options={mappingOptions}
                                        actions={readOnly || disabled ? null : [{
                                            name: 'create-variable',
                                            aa: 'variable-create',
                                            label: t('taskbot:variable-manager-variable-create-label'),
                                            onClick: () => {
                                                const {nodeType, onVariableOpen, onChange, t} = this.props;
                                                const options = {
                                                    input: nodeType === NODE_TYPE_TRIGGER || undefined,
                                                    readOnly: false,
                                                    labelSubmit: t('variables:label-create-select'),
                                                    noSave: true,
                                                };
                                                if (key) {
                                                    const schemaEntry = returnSchema?.find((entry) => getVariableKey(entry.name) === getVariableKey(key));
                                                    if (schemaEntry) {
                                                        options.name = schemaEntry.name;
                                                        if (schemaEntry.type && schemaEntry.type !== VALUE_TYPE_UNDEFINED) {
                                                            options.type = schemaEntry.type;
                                                            switch (schemaEntry.type) {
                                                                case VALUE_TYPE_LIST:
                                                                case VALUE_TYPE_DICTIONARY:
                                                                    if (schemaEntry.subtype && schemaEntry.subtype !== VALUE_TYPE_UNDEFINED) {
                                                                        options.subtype = schemaEntry.subtype;
                                                                    }
                                                                    break;
                                                                case VALUE_TYPE_SESSION:
                                                                    if (schemaEntry.sessionType) {
                                                                        options.sessionType = schemaEntry.sessionType;
                                                                    }
                                                                    break;
                                                            }
                                                        }
                                                    }
                                                    else {
                                                        options.name = key;
                                                    }
                                                }
                                                onVariableOpen('create', null, options, (variable) => {
                                                    setTimeout(() => {
                                                        const value = getFieldValue(name);
                                                        onChange(name, {
                                                            ...TaskbotNodeDetailsReturnTo.getDefaultValue(nodeType === NODE_TYPE_COMMAND),
                                                            ...value,
                                                            [`multiple_${entryId}__variable`]: variable.name,
                                                        });
                                                    }, 20);
                                                });
                                            },
                                        }]}
                                        onSubmit={onAccept}
                                        component={ChoiceField}
                                        readOnly={readOnly}
                                        disabled={disabled}
                                        touched={touched}
                                        showMissingValue
                                    />
                                </ModalFormContent>
                            </ModalForm>
                        );
                    }}
                    getFormValues={getFormValues}
                    getFieldValue={getFieldValue}
                    variables={variables}
                    onSubmit={onSubmit}
                    disabled={disabled}
                    readOnly={readOnly}
                    touched={touched}
                    t={t}
                />
            </div>
        );
    }

    render() {
        const {
            nodeType, isReturnTo,
            returnDescription, returnType, returnRequired, returnDirect, returnSchema, returnSettings,
            name, readOnly, disabled, touched, onSubmit,
            t,
        } = this.props;
        if (!returnType || returnType === VALUE_TYPE_UNDEFINED || returnType === VALUE_TYPE_NONE) {
            return null;
        }

        const currentValue = this.selectValue(this.props);
        if (readOnly && !returnRequired && !currentValue) {
            return null;
        }

        if (nodeType === NODE_TYPE_COMMAND && isReturnTo) {
            if (returnType === VALUE_TYPE_SESSION && returnSettings?.includes(PACKAGE_RETURN_SETTING_SESSION_TARGET)) {
                return (
                    <Field
                        key="session"
                        name={`${name}.mode`}
                        label={this.renderLabel()}
                        options={[
                            {
                                value: 'localSession',
                                label: t('variables:type-session--local'),
                                renderContent: () => this.renderSessionNamespace(`${name}.localSession`, t('variables:type-session-help--local')),
                                hasContentErrorHandler: true,
                            },
                            {
                                value: 'globalSession',
                                label: t('variables:type-session--global'),
                                renderContent: () => this.renderSessionNamespace(`${name}.globalSession`, t('variables:type-session-help--global')),
                                hasContentErrorHandler: true,
                            },
                            {
                                value: 'variable',
                                label: t('variables:type-variable'),
                                renderContent: () => this.renderVariable(null, returnDescription || null, returnRequired),
                                hasContentErrorHandler: true,
                            },
                        ]}
                        onSubmit={onSubmit}
                        component={RioModeField}
                        isReadOnly={readOnly}
                        isDisabled={disabled}
                        touched={touched}
                    />
                );
            }

            if (!returnDirect && (
                returnType === VALUE_TYPE_DICTIONARY ||
                (returnType === VALUE_TYPE_RECORD && !returnSchema?.length)
            )) {
                // If we have a schema of nothing, dont do assignments
                if (!returnRequired && returnSchema?.length === 0) {
                    return (
                        <FieldLabel
                            label={this.renderLabel()}
                            help={returnDescription}
                        >
                            <Help className="taskbottaskbotfield-return-empty">
                                <i>{t('taskbot:taskbot-return-schema-empty')}</i>
                            </Help>
                        </FieldLabel>
                    );
                }

                return (
                    <Field
                        key="multiple"
                        name={`${name}.mode`}
                        label={this.renderLabel()}
                        help={returnDescription || null}
                        options={[
                            {
                                value: 'multiple',
                                label: t('variables:type-multiple'),
                                renderContent: () => this.renderMultiple(),
                                hasContentErrorHandler: true,
                            },
                            {
                                value: 'variable',
                                label: returnType === VALUE_TYPE_DICTIONARY ? t('variables:type-dictionary') : t('variables:type-record'),
                                renderContent: () => this.renderVariable(null, null, returnRequired),
                                hasContentErrorHandler: true,
                            },
                        ]}
                        onSubmit={onSubmit}
                        component={RioModeField}
                        isReadOnly={readOnly}
                        isDisabled={disabled}
                        touched={touched}
                    />
                );
            }
        }

        return this.renderVariable(this.renderLabel(), returnDescription || null, returnRequired);
    }
}

export {TaskbotNodeDetailsReturnTo};
