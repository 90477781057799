import React, {useState, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {PagedTable, ModalForm} from '@automationanywhere/rio-components';

import {ModalFormContent} from '../../../common/ModalFormContent';
import {createStringDataField} from '../../../../util/format';
import {getPaginationFilter, getPaginationSort, getPaginationPage, getPaginationData} from '../../../../util/pagination';
import {DEFAULT_PAGINATION, PAGINATION_PAGE_OPTIONS} from '../../../../store/constants/pages';
import {selectLabelsPagedTable} from '../../../../store/selectors/components';

const UserGroupModal = (props) => {
    const {t, apiAction, modalType, onUpdateField, show, onHide} = props;
    const DEFAULT_SORT = [{id: 'name', orderBy: 'asc'}];
    const FIELDS_LOCKED = ['name'];
    const title = modalType === 'ROLE' ? t('variables:type-usergroup-title-select-role') : t('variables:type-usergroup-title-select-team');
    const totalLabel = modalType === 'ROLE' ? t('roles:title') : t('variables:type-usergroup-title-teams');
    const [selection, setSelection] = useState([]);
    const [userGroup, setUserGroup] = useState([]);
    const labelsPagedTable = useSelector(selectLabelsPagedTable);
    const fields = modalType === 'ROLE' ? [
        createStringDataField('name', t('roles:role-name'), t),
    ] : [
        createStringDataField('name', t('variables:type-usergroup-team-name'), t),
        createStringDataField('description', t('field-description'), t),
    ];

    const getData = useCallback((signal, filter, sort, pagination) => {
        return apiAction(signal, {
            fields: [],
            filter: getPaginationFilter(fields, filter),
            sort: getPaginationSort(sort),
            page: getPaginationPage(pagination),
        })
            .then(getPaginationData);
    }, []);

    return (
        <ModalForm
            id="user-group-modal"
            title={title}
            labelCancel={t('label-cancel')}
            labelSubmit={t('action-select')}
            onSubmit={() => {
                onUpdateField(userGroup);
                onHide();
            }}
            submitDisabled={selection.length === 0}
            onHide={onHide}
            labelAltWorking
            show={show}
        >
            <ModalFormContent>
                <PagedTable
                    name="group-value-user-group"
                    theme="default"
                    selectType="radio"
                    defaultFieldId="name"
                    labelAriaSelectRow=""
                    labelAriaFilter=""
                    fields={fields}
                    fieldsLocked={FIELDS_LOCKED}
                    selection={selection}
                    onSelection={(selection, data) => {
                        const [id] = selection;
                        const userGroup = (id && data.find((group) => id === group.id)) || null;
                        setSelection(selection);
                        setUserGroup([userGroup.name]);
                    }}
                    title={totalLabel}
                    sort={DEFAULT_SORT}
                    getData={getData}
                    pagination={DEFAULT_PAGINATION}
                    pageOptions={PAGINATION_PAGE_OPTIONS}
                    {...labelsPagedTable}
                />
            </ModalFormContent>
        </ModalForm>
    );
};

UserGroupModal.displayName = 'UserGroupModal';

export {UserGroupModal};
