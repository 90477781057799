
import {DataField, DataType} from '@automationanywhere/rio-components';

import {COLUMN_WIDTH_STRING} from '../../../../util/format';

const STATUS_LABELS = {
    OAUTH_TOKEN_STATUS_UNKNOWN: '--',
    OAUTH_TOKEN_STATUS_ACTIVE: 'Active',
    OAUTH_TOKEN_STATUS_EXPIRED: 'Expired - login to re-authenticate',
    OAUTH_TOKEN_STATUS_NOT_AVAILABLE: 'Not Available',
};

export const createStatusDataField = (id, label, t, options = {}) => new DataField({
    id,
    label,
    render: (value) => STATUS_LABELS[value] || value,
    renderString: (value) => STATUS_LABELS[value] || value,
    type: DataType.STRING,
    columnWidth: COLUMN_WIDTH_STRING,
    ...options,
});
