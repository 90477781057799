import React, {Component} from 'react';
import {DetailsPane, GridLayout, FieldLabel} from '@automationanywhere/rio-components';

import {FormattedDate} from '../FormattedDate';
import {User} from '../../resources/users';

class CommonDetailsPane extends Component {
    static displayName = 'CommonDetailsPane';

    render() {
        const {className, label, type, subtype, object, children, t} = this.props;
        const columnSizing = subtype ? {sm: 6, lg: 3} : {sm: 4};
        return (
            <DetailsPane className={className} label={label || t('label-general-details')}>
                <GridLayout {...DetailsPane.GUTTERS}>
                    <GridLayout.Row>
                        <GridLayout.Column {...columnSizing}>
                            <FieldLabel label={t('field-last-modified')} readOnly>
                                <FormattedDate date={object.updatedOn || object.lastModified}/>
                            </FieldLabel>
                        </GridLayout.Column>
                        <GridLayout.Column {...columnSizing}>
                            <FieldLabel label={t('field-modified-by')} readOnly>
                                <User id={object.updatedBy ?? object.lastModifiedBy} isSystemZero column/>
                            </FieldLabel>
                        </GridLayout.Column>
                        <GridLayout.Column {...columnSizing}>
                            <FieldLabel label={t('label-object-type')} readOnly>
                                {type}
                            </FieldLabel>
                        </GridLayout.Column>
                        {subtype && (
                            <GridLayout.Column {...columnSizing}>
                                <FieldLabel label={`${type} ${t('label-subtype')}`} readOnly>
                                    {subtype}
                                </FieldLabel>
                            </GridLayout.Column>
                        )}
                    </GridLayout.Row>
                    {children}
                </GridLayout>
            </DetailsPane>
        );
    }
}

export default CommonDetailsPane;
